(function ($) {
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	const CB_Main = {
		formObject: {},
		init() {

			$(".cb_add_new_field").on("click", this.cloneFileds);
			$(".cb_next-step").on("click", this.gotoNextStep)
			$(".cb_prev-step").on("click", this.gotoPrevStep);
			$(document).on("click", ".cb_make_order", this.makeOrder);
			$("#step-1 .cb_form__wrapper").on('change', debounce(CB_Main.handleDynamicCalcPrice, 500));
			$(document).on("click", ".remove-form-group", this.removeWaypoint);

		},
		cloneFileds(ev) {
			const wrapper = $(ev.currentTarget).parents().find(".form-copy-wrapper");

			const group = wrapper.find(".form-group");

			const groupValue = group.html();


			const newIndex = $(".form-copy-wrapper").children().length - 1;

			const searchGroup = new RegExp(group.data("index"), "g");
			let newGroup = groupValue.replace(searchGroup, newIndex).replace(/from/g, "waypoints").replace('Miejsce startu', 'Przez').replace('Start place', 'Via');
			newGroup += '<button class="remove-form-group">&times;</button>';

			wrapper.append(`<div class="form-group" data-index="${newIndex}">${newGroup}</div>`);


			const newInput = document.querySelector(`.form-group[data-index="${newIndex}"] input[name*="waypoints"]`);
			newInput.setAttribute("name", `waypoints[]`)
			if (newInput) {
				var newAcf = new google.maps.places.Autocomplete(newInput)
				newAcf.setFields(['address_components', 'geometry', 'icon', 'name']);
				newAcf.inputId = newInput.id;
				newInput.classList.add('autoplaces');

		        google.maps.event.addListener(newAcf, 'place_changed', autocompleteHandler);
			}
		},
		removeWaypoint(ev) {
			const group = $(ev.target).parents('.form-group');
			const input = group.find('input');
			const inputIndex = input.attr('id')[input.attr('id').length - 1];
			if (waypts[inputIndex]) {
				delete waypts[inputIndex];
			}
			group.remove();
			calcRoute();
		},
		gotoNextStep(ev) {
			$(".non-valid").removeClass("non-valid");
			if ( !CB_Main.getFormData(ev.target) ) {
				return;
			}

			const currentStep = $(ev.target).parents(".cb_step");
			currentStep.hide();
			currentStep.next().show();
			$("#currentStep").text( parseInt($("#currentStep").text())+1 );

			if (currentStep.next().attr('id') === "step-3") {
				CB_Main.showOrderData();
				CB_Main.calcuatePrice();
				$(".cb_make_order").attr("disabled", true);
			}

			$('html, body').animate({
                scrollTop: $("#" + t.colFormId).offset().top - $(".page-header").height()
            }, 500);

		},
		gotoPrevStep(ev) {
			const currentStep = $(ev.target).parents(".cb_step");
			currentStep.hide();
			currentStep.prev().show();
			$("#currentStep").text( parseInt($("#currentStep").text())-1 );
			if (currentStep.prev().attr('id') === "step-1") {
				delete CB_Main.formObject.waypoints
			}

			$('html, body').animate({
                scrollTop: $("#" + t.colFormId).offset().top - $(".page-header").height()
            }, 500);


		},
		getFormData(element) {
			let validFlag = true;
			if (element.classList.contains('tracking-next-route')) {
				delete CB_Main.formObject.waypoints;
			}
			const fields = $(element).parents(".cb_form__wrapper").find("input, select, textarea");

			let startDateElement, startTimeElement;

			fields.each(function(el) {
				if ( fields[el].hasAttribute("required") && ( fields[el].value === "" || fields[el].value === null ) ) {
					fields[el].classList.add("non-valid");
					validFlag = false;
				}
				if ( fields[el].type === 'checkbox' && fields[el].hasAttribute("required") ) {
					if( !fields[el].checked ) {
						fields[el].classList.add("non-valid");
						validFlag = false;
					}
				}

				const elName = fields[el].getAttribute("name")

				if (elName === "date_start") startDateElement = fields[el];
				if (elName === "time_start") startTimeElement = fields[el];

				if (elName === "passenger_counter") {
					const passengers = parseInt(fields[el].value);
					if( passengers > 8 || passengers < 1 ) {
						fields[el].classList.add("non-valid");
						validFlag = false;
					}
				}

				if (elName.indexOf("[") > -1) {
					const cutedName = elName.substr(0, elName.indexOf("["));
					if(CB_Main.formObject.hasOwnProperty(cutedName)) {
						CB_Main.formObject[cutedName] = CB_Main.formObject[cutedName].concat(fields[el].value);
					} else {
						CB_Main.formObject[cutedName] = [];
						CB_Main.formObject[cutedName] = CB_Main.formObject[cutedName].concat(fields[el].value);
					}

				} else {
					CB_Main.formObject[elName] = fields[el].value;
 				}
			})

			if (startDateElement && startTimeElement) {
				const dateValidation = this.validateStartDate(startDateElement, startTimeElement)
				validFlag = (validFlag && dateValidation);
			}

			return validFlag;
		},
		validateStartDate(startDateElement, startTimeElement) {
			const dateString = `${startDateElement.value}T${startTimeElement.value}`;

			const now = new Date();

			const isBeforeNow = new Date(dateString).getTime() < now.getTime();
			if (isBeforeNow) {

				const startDayDate = new Date(startDateElement.value);

				if (startDayDate.getTime() < new Date().setHours(0,0,0,0)) {
					startDateElement.classList.add("non-valid");
				}
				if (startDayDate.getDate() === now.getDate() && startDayDate.getMonth() === now.getMonth() && startDayDate.getFullYear() === now.getFullYear()) {
					// is today
					if (new Date(dateString).getHours() <= now.getHours() && new Date(dateString).getMinutes() <= now.getMinutes()) {
						startTimeElement.classList.add("non-valid");
					}
				}
			}

			return !isBeforeNow;

		},
		showOrderData() {
			let type = "";
			let car = $(`option[value='${CB_Main.formObject.category_id}']`).first().text();
			let routeInfo = "";

			if (CB_Main.formObject.type === "hours") {
				type = t.typeHourly;
				routeInfo += `<p><b>${t.fortime} ${CB_Main.formObject.duration} ${t.fortimehours}</b></p>`;
			} else if (CB_Main.formObject.type === "kilometers") {
				type = t.typeRoute;
				if(CB_Main.formObject.waypoints) {
				routeInfo += `<p><b>${t.via}:</b><br/> `;
					CB_Main.formObject.waypoints.forEach(point => {
						routeInfo += "  " + point + "<br/> ";
					})
				routeInfo += `</p>`;
				}
				routeInfo += `<p><b>${t.to}:</b> ${CB_Main.formObject.to}</p>`
			}

			let personal_data = "";
			personal_data += `<p>${CB_Main.formObject.firstname} ${CB_Main.formObject.lastname}</p>`;
			personal_data += `<p>${CB_Main.formObject.address}</p>`;
			personal_data += `<p>${CB_Main.formObject.zipcode}, ${CB_Main.formObject.city}</p>`;
			personal_data += `<p><b>e-mail:</b> ${CB_Main.formObject.email}</p>`;
			personal_data += `<p><b>tel:</b> ${CB_Main.formObject.phone}</p>`;


			let order_date = "";
			order_date += `<p><b>${t.type}:</b> ${type}</p>`;
			order_date += `<p><b>${t.car}:</b> ${car}</p>`;
			order_date += `<p><b>${t.endDate}:</b><br/> ${CB_Main.formObject.date_start} ${CB_Main.formObject.time_start}</p>`;
			order_date += `<p><b>${t.from}:</b> ${CB_Main.formObject.from}</p>`;
			order_date += routeInfo;


			let additional = "";
			if( CB_Main.formObject.passenger_counter ) {
				additional += `<p>${t.passNumber}: ${CB_Main.formObject.passenger_counter}</p>`;
			}
			if( CB_Main.formObject.additional_info ) {
				additional += `<p>${t.note}:<br/> ${CB_Main.formObject.additional_info}</p>`;
			}
			if( additional.length < 1) {
				additional += `<p>Nie podano</p>`;
			}



			$("#cb_person").html("");
			$("#cb_person").append(personal_data);

			$("#cb_additional").html("")
			$("#cb_additional").append(additional);

			$("#cb_order").html("");
			$("#cb_order").append(order_date);

		},
		calcuatePrice(step1 = false) {
			if (step1) {
				$(`.pane--${CB_Main.formObject.type} .expected-cost`).html('<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>');
			}
			let data = {
				"action" : "calculate_pricing",
				"category_id": CB_Main.formObject.category_id,
				"type": CB_Main.formObject.type,
				"from": CB_Main.formObject.from,
				"date_start": CB_Main.formObject.date_start + " " + CB_Main.formObject.time_start
			}
			if (data.type === "hours") {
				data = Object.assign(data, {
					"duration": CB_Main.formObject.duration
				})
			} else {
				data = Object.assign(data, {
					"to": CB_Main.formObject.to,
					"waypoints": CB_Main.formObject.waypoints
				})
			}


			$.post(v.ajaxurl, data, function(res) {
				// console.log(res);
				if (res.hasOwnProperty("error")) {
					$("#cb_order").append(`<p class="cb_price cb_price--error">${res.error}</p>`);
// 					$(".cb_make_order").attr("disabled", true);
				} else if(res.hasOwnProperty("formatted_price")) {
					if (step1) {
						$(`.pane--${CB_Main.formObject.type} .expected-cost`).html(`<div>${res.formatted_price_string_with_label}</div>`);
						return;
					}

					$("#cb_order").append(res.formatted_price_string);
					$(".cb_make_order").attr("disabled", null);
					$(".cb_make_order").html(t.orderAndPay);

				}
			})
		},
		makeOrder() {
			const data_agree = document.getElementById("cb_data_agree");
			const cb_data_agree_sms = document.getElementById("cb_data_agree_sms");
			const cb_data_agree_promo = document.getElementById("cb_data_agree_promo");
			const cb_data_agree_marketing = document.getElementById("cb_data_agree_marketing");

			const data_agree_route  = document.getElementById("cb_data_agree_route");
			const cb_data_agree_sms_route = document.getElementById("cb_data_agree_sms_route");
			const cb_data_agree_promo_route = document.getElementById("cb_data_agree_promo_route");
			const cb_data_agree_marketing_route = document.getElementById("cb_data_agree_marketing_route");

			if (data_agree.checked || data_agree_route.checked) {
				$(".cb_make_order").html('<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>');
				$(".cb_make_order").attr("disabled", true);
				$(".cb_prev-step").attr("disabled", true);
				let data = {
					"action" : "set_booking",
					"category_id": CB_Main.formObject.category_id,
					"type": CB_Main.formObject.type,
					"from": CB_Main.formObject.from,
					"date_start": CB_Main.formObject.date_start + " " + CB_Main.formObject.time_start,
					"firstname": CB_Main.formObject.firstname,
					"lastname": CB_Main.formObject.lastname,
					"address": CB_Main.formObject.address,
					"zipcode": CB_Main.formObject.zipcode,
					"city": CB_Main.formObject.city,
					"phone": CB_Main.formObject.phone,
					"email": CB_Main.formObject.email,
				}
				if(cb_data_agree_promo.checked || cb_data_agree_promo_route.checked) {
					data.agreement_promo = 1;
				}
				if(cb_data_agree_sms.checked || cb_data_agree_sms_route.checked) {
					data.agreement_sms = 1;
				}
				if(cb_data_agree_marketing.checked || cb_data_agree_marketing_route.checked) {
					data.agreement_marketing = 1;
				}
				if (data.type === "hours") {
					data = Object.assign(data, {
						"duration": CB_Main.formObject.duration
					})
				} else {
					data = Object.assign(data, {
						"to": CB_Main.formObject.to,
						"waypoints": CB_Main.formObject.waypoints
					})
				}

				const originalBottomContent = $(".cb_summary_bottom").html();

				$.post(v.ajaxurl, data, function(res) {
					// console.log(res);
					if (res.hasOwnProperty("error")) {
						$(".cb_prev-step").attr("disabled", null);
						$(".cb_summary_bottom").fadeOut();
						$(".cb_summary_bottom").html("");
						var msg = res.msg ? res.msg : res.error;
						$(".cb_summary_bottom").append(`<div class="cb_success"><h4 class="cb_success-heading--error">${t.error}</h4><p class="cb_success-text">${msg}</p></div>`)
						$(".cb_summary_bottom").fadeIn();
						$(".cb_success").addClass("show");

						setTimeout(() => {
							$(".cb_summary_bottom").fadeOut();
							$(".cb_summary_bottom").html(originalBottomContent);
							$(".cb_summary_bottom").fadeIn();
						}, 5000)

					} else if(res.hasOwnProperty("msg")) {
						$(".cb_summary_bottom").fadeOut();
						$(".cb_summary_bottom").html("");
						$(".cb_summary_bottom").append(`<div class="cb_success"><h4 class="cb_success-heading">${t.success}</h4><p class="cb_success-text">${res.msg}</p></div>`)
						$(".cb_summary_bottom").fadeIn();
						$(".cb_success").addClass("show");
						$(".cb_prev-step").remove();
						if(res.url)	{
							setTimeout(() => {
								window.location = res.url;
							}, 5000)
						}
					}
				})
			} else {
				$(".cb_agree").append(`<p class='cb_agree_false'>${t.dataAgreeError}</p>`);
				setTimeout(function() {
					$(".cb_agree_false").fadeOut();
					$(".cb_agree_false").remove();
				}, 5000)
			}
		},
		handleDynamicCalcPrice(e) {
			if (e.target.getAttribute("type") === "checkbox") return;
			const fields = $(this).find('input, select');
			delete CB_Main.formObject.waypoints;
			fields.each(function (el) {
				const elName = fields[el].getAttribute("name");
				if (elName.indexOf("[") > -1) {
					const cutedName = elName.substr(0, elName.indexOf("["));
					if (CB_Main.formObject.hasOwnProperty(cutedName)) {
						CB_Main.formObject[cutedName] = CB_Main.formObject[cutedName].concat(fields[el].value);
					}
					else {
						CB_Main.formObject[cutedName] = [];
						CB_Main.formObject[cutedName] = CB_Main.formObject[cutedName].concat(fields[el].value);
					}
				}
				else {
					CB_Main.formObject[elName] = fields[el].value;
				}
			});
			if (CB_Main.formObject.category_id && (CB_Main.formObject.category_id !== "" && CB_Main.formObject.category_id !== null)
				&& CB_Main.formObject.type && (CB_Main.formObject.type !== "" && CB_Main.formObject.type !== null)
				&& CB_Main.formObject.from && (CB_Main.formObject.from !== "" && CB_Main.formObject.from !== null)
				&& CB_Main.formObject.date_start && (CB_Main.formObject.date_start !== "" && CB_Main.formObject.date_start !== null)
				&& CB_Main.formObject.time_start && (CB_Main.formObject.time_start !== "" && CB_Main.formObject.time_start !== null)) {
				if (CB_Main.formObject.type === "hours" && (CB_Main.formObject.duration !== "" && CB_Main.formObject.duration !== null)) {
					CB_Main.calcuatePrice(true);
				}
				else if (CB_Main.formObject.type === "kilometers" && (CB_Main.formObject.to !== "" && CB_Main.formObject.to !== null)) {
					CB_Main.calcuatePrice(true);
				}
			}
		},

	}
	CB_Main.init();
})(jQuery);